<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Inviter un utilisateur</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Inviter un utilisateur" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <form-category title="Invitation d'espace" subtitle="Veuillez sélectionner un professionnel à inviter"
                       class="mt-4"
        />
        <div>
          <h3 class="subtitle-2 font-weight-bold primary--text">Envoyer une invitation rapide</h3>
          <div class="text-caption mb-1">
            Générez un lien à partager par e-mail, SMS ou Messenger. Dès que la personne aura accepté l'invitation,
            vous pourrez la retrouver dans votre espace. Faites attention si vous partagez un lien dans un endroit
            public.
            Chaque lien d'invitation n'est utilisable qu'une seule fois et expire automatiquement après 30 jours
          </div>
          <v-text-field ref="invitationLink" class="mb-2" :value="invitationLink" readonly :loading="generatingInvitationLink"
                        dense outlined filled hide-details
          >
            <template #append>
              <v-btn small style="margin-top: -2px" :disabled="!invitationLink" @click="copyInvitationLink">Copier</v-btn>
            </template>
          </v-text-field>
          <v-btn x-small :disabled="generatingInvitationLink" @click="generateInvitationLink">
            Générer un nouveau lien
          </v-btn>
        </div>

        <div>
          <h3 class="subtitle-2 font-weight-bold primary--text mt-6">Inviter</h3>
          <professional-autocomplete v-model="guest" dense />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn x-small class="mx-1" @click="$emit('close')">Annuler</v-btn>
          <v-btn color="secondary" x-small class="mx-1" :disabled="!guest || submitting" :loading="submitting" @click="invite">
            Inviter
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import ProfessionalAutocomplete from '@/modules/professionals/components/ProfessionalAutocomplete'
  import {createPrivateInvitationLink, inviteUser} from '@/modules/spaces/api/invitations'
  import {Invitation, Space} from '@/models'
  import config from '@/config'

  export default {
    name: 'SpaceProfessionalInvitationModal',
    components: {ProfessionalAutocomplete, FormCategory, ModalsSidebar},
    data() {
      return {
        guest: null,
        submitting: false,
        invitationLink: null,
        generatingInvitationLink: true
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    created() {
      this.generateInvitationLink()
    },
    methods: {
      async invite() {
        this.submitting = true
        try {
          let {data} = await inviteUser(this.$store.state.core.space, this.guest.uid)
          this.space.$mutate(space => {
            ++space.total_guests
          })
          Invitation.save(data)
          this.$root.$emit('space:invite', data.uid)

          this.$emit('close')
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Professionnel invité',
            subtitle: 'Le professionnel a été invité à rejoindre l\'espace.'
          })

        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.submitting = false
        }
      },
      async generateInvitationLink() {
        try {
          this.generatingInvitationLink = true
          this.invitationLink = null
          let {data} = await createPrivateInvitationLink(this.$store.state.core.space)
          this.invitationLink = `${config.urls.homepage}/join/${data.token}`
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.generatingInvitationLink = false
        }
      },
      copyInvitationLink() {
        this.$refs.invitationLink.$refs.input.select()
        document.execCommand('copy')
      }
    }
  }
</script>
