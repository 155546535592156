import api from '@/api/api'

// @deprecated removed from api
export function get(uid) {
  return api.get(`/v2/professionals/${uid}`)
}

export function search(page = 1, limit = 10, filters = {}, order) {
  return api.post('/v3/users/search', {page, limit, order, filters: {
    type: 'professional',
    ...filters
  }, projections: ['professional_address', 'professional_job', 'professional_skills', 'professional_specialties']})
}

export function referPatient(professionalUid, patientUid, criticality_level, reason, medical_history, ongoing_treatments) {
  return api.post(`/v3/medical-space/professionals/${professionalUid}/refer-patient/${patientUid}`, {
    criticality_level,
    reason,
    medical_history,
    ongoing_treatments
  })
}
