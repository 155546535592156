<template>
  <v-autocomplete v-model="localValue" label="Rechercher un professionnel"
                  :loading="searching"
                  :search-input.sync="search"
                  item-value="uid"
                  item-text="full_name"
                  hide-no-data
                  no-filter
                  clearable
                  :items="items"
                  return-object
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item transition="slide-y-transition" v-bind="attrs" v-on="on">
        <v-list-item-avatar size="50">
          <user-avatar-img :user="item" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.full_name }}</v-list-item-title>
          <v-list-item-subtitle>
            <span class="secondary--text text--darken-1 font-weight-medium">{{ getJob(item.job.name) }}</span>
            <template v-if="item.main_address">
              à {{ item.main_address.city.name }}
            </template>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  import conditionalData from '@/mixins/conditionalData'
  import {debounce} from 'throttle-debounce'
  import {empty} from '@/utils/variables'
  import {search} from '@/modules/professionals/api'

  export default {
    name: 'ProfessionalAutocomplete',
    components: {UserAvatarImg},
    mixins: [conditionalData],
    props: {
      value: Object
    },
    data() {
      return {
        searching: false,
        search: null,
        items: []
      }
    },
    computed: {
      localValue: {
        set(value) {
          this.$emit('input', value)
        },
        get() {
          return this.value
        }
      }
    },
    watch: {
      search: debounce(300, async function (val) {
        if (!empty(val) && (!this.value || val !== this.value.full_name)) {
          this.$emit('change')
          try {
            this.searching = true
            this.items = (await search(1, 10, {
              search_term: val,
              status: 'ENABLE'
            })).data.list
          } catch (e) {
            this.$handleApiError(e)
          } finally {
            this.searching = false
          }
        }
      })
    }
  }
</script>
